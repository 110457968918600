import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import Paragraph from "antd/es/skeleton/Paragraph.js";

function AddEditChooseQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
}) {
  const { categoryID, questionID, examID, n_paragraph } = useParams();

  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;
  const [QuestionDataSuccess, QuestionErrors, loading] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );

  const [question, setQuestion] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [feedback, setFeedback] = useState("");
  const [difficulty, setDifficulty] = useState("easy");
  const [correct_answer, setCorrectAnswer] = useState("");
  const [degree, setDegree] = useState("1");
  const [paragrapgh, setParagrapgh] = useState("");

  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let api =
    type === "homework"
      ? process.env.REACT_APP_ADMIN_ADD_HOMEWORK_CHOOSE_QUESTIONS_API
      : type === "quiz"
      ? process.env.REACT_APP_ADMIN_ADD_QUIZ_CHOOSE_QUESTIONS_API
      : type === "exam"
      ? process.env.REACT_APP_ADMIN_TRIAL_EXAM_ADD_CHOOSE_QUESTION_API
      : edit
      ? process.env.REACT_APP_EDIT_QUESTION_API
      : n_paragraph
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAMS_PARAGRAPH_ADD_QUESTION_TAB_API
      : `${process.env.REACT_APP_ADD_CHOOSE_QUESTION_API}${categoryID}`;
  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  useEffect(() => {
    if (QuestionDataSuccess) {
      setParagrapgh(QuestionDataSuccess.data.paragraph);
      setQuestion(QuestionDataSuccess.data.question);
      setAnswer1(QuestionDataSuccess.data.answer1);
      setAnswer2(QuestionDataSuccess.data.answer2);
      setAnswer3(QuestionDataSuccess.data.answer3);
      setAnswer4(QuestionDataSuccess.data.answer4);
      setFeedback(QuestionDataSuccess?.data.feedback);
      setDifficulty(QuestionDataSuccess.data.difficulty);
      setCorrectAnswer(QuestionDataSuccess.data.correct_answer);
      setDegree(QuestionDataSuccess.data?.degree);
    }
  }, [QuestionDataSuccess, paragrapgh]);

  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (answer1 !== "") {
      setErrors("");
    } else if (answer2 !== "") {
      setErrors("");
    } else if (answer3 !== "") {
      setErrors("");
    } else if (answer4 !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    } else if (correct_answer !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (feedback !== "") {
      setErrors("");
    }
  }, [
    question,
    answer1,
    answer2,
    answer3,
    answer4,
    difficulty,
    correct_answer,
    degree,
    feedback,
  ]);
  const { register, handleSubmit, getValues, reset } = useForm({
    values: {
      question: question,
      answer1: answer1,
      answer2: answer2,
      answer3: answer3,
      answer4: answer4,
      feedback: feedback,
      correct_answer: correct_answer,
      degree: degree,
      difficulty: difficulty,
      paragrapgh: paragrapgh,
    },
    mode: "onTouched",
  });
  const onSubmit = (data) => {
    /*     e.preventDefault(); */
    if (question === "") {
      setErrors("question");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (answer1 === "") {
      setErrors("answer1");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (answer2 === "") {
      setErrors("answer2");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (answer3 === "") {
      setErrors("answer3");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (answer4 === "") {
      setErrors("answer4");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (difficulty === "" && !type) {
      setErrors("difficulty");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (correct_answer === "") {
      setErrors("correct_answer");
      toast.error("برجاء ملئ جميع البيانات المطلوبة");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("من فضلك ادخل رقم اكبر من صفر");
    }
    let finalData =
      type === "homework"
        ? {
            question: data.question,
            answer1: data.answer1,
            answer2: data.answer2,
            answer3: data.answer3,
            answer4: data.answer4,
            feedback: data.feedback ? data.feedback : null,
            correct_answer: data.correct_answer,
            degree: data.degree,
            homework_id: id,
          }
        : type === "quiz"
        ? {
            question: data.question,
            answer1: data.answer1,
            answer2: data.answer2,
            answer3: data.answer3,
            answer4: data.answer4,
            feedback: data.feedback ? data.feedback : null,
            correct_answer: data.correct_answer,
            degree: data.degree,
            quiz_id: id,
          }
        : n_paragraph
        ? {
            question: data.question,
            answer1: data.answer1,
            answer2: data.answer2,
            answer3: data.answer3,
            answer4: data.answer4,
            feedback: data.feedback ? data.feedback : null,
            correct_answer: data.correct_answer,
            degree: data.degree,
            id: n_paragraph,
          }
        : {
            exam_id: examID,
            question: data.question,
            answer1: data.answer1,
            answer2: data.answer2,
            answer3: data.answer3,
            answer4: data.answer4,
            feedback: data.feedback ? data.feedback : null,
            correct_answer: data.correct_answer,
            difficulty: data.difficulty,
            degree: data.degree,
            id: edit ? questionID : categoryID,
          };
    if (type === "homework") {
      data = {
        question: data.question,
        answer1: data.answer1,
        answer2: data.answer2,
        answer3: data.answer3,
        answer4: data.answer4,
        feedback: data.feedback ? data.feedback : null,
        correct_answer: data.correct_answer,
        degree: data.degree,
        homework_id: id,
      };
    } else if (type === "quiz") {
      data = {
        question: data.question,
        answer1: data.answer1,
        answer2: data.answer2,
        answer3: data.answer3,
        answer4: data.answer4,
        feedback: data.feedback ? data.feedback : null,
        correct_answer: data.correct_answer,
        degree: data.degree,
        quiz_id: id,
      };
    } else if (type === "paragraph") {
      data = {
        paragraph: data.paragraph,
      };
    } else if (type === "exam") {
      data = {
        question: data.question,
        answer1: data.answer1,
        answer2: data.answer2,
        answer3: data.answer3,
        answer4: data.answer4,
        feedback: data.feedback ? data.feedback : null,
        correct_answer: data.correct_answer,
        degree: data.degree,
        exam_id: examID,
      };
    } else {
      data = {
        question: data.question,
        answer1: data.answer1,
        answer2: data.answer2,
        answer3: data.answer3,
        answer4: data.answer4,
        feedback: data.feedback ? data.feedback : null,
        correct_answer: data.correct_answer,
        difficulty: data.difficulty,
        degree: data.degree,
        id: edit ? questionID : categoryID,
      };
    }
    setSubmitQuestion({
      flag: "addEditQuestionRequest",
      dependency: !submitQuestion.dependency,
      data: finalData,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setParagrapgh("");
      setQuestion("");
      setAnswer1("");
      setAnswer2("");
      setAnswer3("");
      setAnswer4("");
      setDifficulty("");
      setCorrectAnswer("");
      setDegree("");
      setFeedback("");
      setErrors("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section className="flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light">
      {!type && (
        <HelmetTags title="بنك الاسئلة | Sir karam makram"></HelmetTags>
      )}
      <form
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className="form form-container my-20 flex flex-col items-center "
      >
        <h2 className="my-20 text-center text-3xl font-bold">
          {edit
            ? "من فضلك قم بملئ البيانات لتعديل السؤال "
            : "من فضلك قم بملئ البيانات لإضافة السؤال"}{" "}
        </h2>

        <div className=" question mb-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <div className="mb-3 w-full flex justify-between items-center gap-5 md:flex-col md:items-center">
            <div className=" bubble flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-start gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="bubble"
                    name="bubble"
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAnswer1("A");
                        setAnswer2("B");
                        setAnswer3("C");
                        setAnswer4("D");
                      } else {
                        setAnswer1("");
                        setAnswer2("");
                        setAnswer3("");
                        setAnswer4("");
                      }
                    }}
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="bubble"
                  >
                    Bubble Sheet
                  </label>
                </div>
              </div>
            </div>
            <h2 className="mb-3 text-3xl font-semibold">السؤال</h2>
          </div>

          <textarea
            className=" focus:bg-white py-1"
            onChange={(e) => setQuestion(e.target.value)}
            {...register("question", {
              required: true,
            })}
            defaultValue={question}
          ></textarea>
          {errors === "question" && (
            <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.question && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 1-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">الإجابة الأولي</h2>

          <textarea
            className=" focus:bg-white py-1"
            defaultValue={answer1}
            onChange={(e) => setAnswer1(e.target.value)}
            {...register("answer1", {
              required: true,
            })}
          ></textarea>
          {errors === "answer1" && (
            <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer1 && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer1[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 2-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">الإجابة الثانية</h2>

          <textarea
            className=" focus:bg-white py-1"
            onChange={(e) => setAnswer2(e.target.value)}
            defaultValue={answer2}
            {...register("answer2", {
              required: true,
            })}
          ></textarea>
          {errors === "answer2" && (
            <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer2 && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer2[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 3-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">الإجابة الثالثة</h2>

          <textarea
            className=" focus:bg-white py-1"
            defaultValue={answer3}
            onChange={(e) => setAnswer3(e.target.value)}
            {...register("answer3", {
              required: true,
            })}
          ></textarea>
          {errors === "answer3" && (
            <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer3 && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer3[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 4-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">الإجابة الرابعة </h2>

          <textarea
            className=" focus:bg-white py-1"
            defaultValue={answer4}
            onChange={(e) => setAnswer4(e.target.value)}
            {...register("answer4", {
              required: true,
            })}
          ></textarea>
          {errors === "answer4" && (
            <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer4 && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer4[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------Feedback-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-end gap-2 xsl:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-3xl font-semibold">فيدباك</h2>

          <textarea
            className="focus:bg-white py-1"
            defaultValue={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            {...register("feedback")}
          ></textarea>
        </div>
        {/**
         * //!---------right answer & difficulty & degree
         */}
        <div className="flex w-full items-start justify-evenly gap-10 md:flex-col-reverse my-10">
          {/**
           * //!------right answer -----
           */}
          <div
            className={` flex w-1/3 flex-col items-end justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="correct_answer">
              الإجابة الصحيحة
            </label>
            <select
              onChange={(e) => setCorrectAnswer(e.target.value)}
              className="input"
              name="correct_answer"
              id="correct_answer"
              defaultValue={correct_answer}
              {...register("correct_answer", {
                required: true,
              })}
            >
              <option value="answer1">الإجابة الأولي</option>
              <option value="answer2">الإجابة الثانية</option>
              <option value="answer3">الإجابة الثالثة</option>
              <option value="answer4">الإجابة الرابعة</option>
            </select>

            {errors === "correct_answer" && (
              <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors
                  ?.correct_answer && (
                  <p className="w-full text-end text-[12px] text-red-900  ">
                    {
                      addEditQuestionErrors?.response?.data?.errors
                        ?.correct_answer[0]
                    }
                  </p>
                )
            }
          </div>
          {/**
           * //!------difficulty -----
           */}{" "}
          {!type && !lectureQuestion && (
            <div
              className={` flex w-1/3 flex-col items-end justify-center gap-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="difficulty">
                المستوي{" "}
              </label>
              <select
                defaultValue={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                className="input w-full "
                name="difficulty"
                id="difficulty"
                {...register("difficulty")}
              >
                <option hidden value=""></option>
                <option value="easy">سهل</option>
                <option value="medium">متوسط</option>
                <option value="hard">صعب</option>
              </select>

              {errors === "difficulty" && (
                <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.difficulty[0]
                      }
                    </p>
                  )
              }
            </div>
          )}
          <div className=" Degree flex w-1/3  flex-col items-end justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="degree">
              الدرجة
            </label>
            <input
              className="input w-full "
              type="number"
              inputMode="numeric"
              min={1}
              defaultValue={degree}
              onChange={(e) => setDegree(e.target.value)}
              id="degree"
              placeholder="000"
              name="degree"
              autoComplete="on"
              {...register("degree", {
                required: true,
              })}
            />

            {errors === "degree" && (
              <p className="text-[12px] text-red-900 ">برجاء ملئ هذا الحقل</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className="text-[12px] text-red-900 ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
        </div>

        <button
          disabled={submitLoading && submitQuestion.flag}
          className="login-button  mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "تعديل السؤال" : "اضافة السؤال"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditChooseQuestion;
