import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

function AdminExams() {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="الإختبارات | Sir karam makram"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          الإختبارات
        </h2>{" "}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("exam_add")
          )) && (
          <Link
            to="/admin/exams/add-exam"
            className="ml-4 md:w-full flex items-center justify-center rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            اضافة إختبار
          </Link>
        )}
        {allExamsloading ? (
          <Loader />
        ) : (
          <div className="lectures grid-auto-fit w-full">
            {allExamsData?.data?.length === 0 ? (
              <p>لا يوجد محاضرات</p>
            ) : (
              allExamsData?.data?.map((Card) => (
                <SingleCourse
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  admin
                  exam
                  isExam
                  lecture={Card}
                  key={Card.key}
                />
              ))
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default AdminExams;
