import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

const SendWhatsappMessage = () => {
  const AuthState = useAuthState();
  const { studentID } = useParams();
  const [message, setMesaage] = useState("");

  const [sendMessage, setSendMessage] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let fullMessage = {
    message: message,
    student_id: studentID,
  };

  const [
    sendMessageToWhatsappSucsses,
    sendMessageErrors,
    sendMessageToWhatsappLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_SEND_WHATSAPP_MESSAGE_API,
    "POST",
    sendMessage.flag,
    sendMessage.dependency,
    sendMessage.data
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    setSendMessage({
      flag: "sendMessageToWhatsapp",
      dependency: !sendMessage.dependency,
      data: fullMessage,
    });
  };

  useEffect(() => {
    if (sendMessageToWhatsappSucsses) {
      toast.success(sendMessageToWhatsappSucsses?.message);
      setMesaage();
    }
  }, [sendMessageToWhatsappSucsses]);

  useEffect(() => {
    if (sendMessageErrors) {
      toast.error(sendMessageErrors?.response?.data.message);
    }
  }, [sendMessageErrors]);

  return (
    <>
      <HelmetTags title="واتساب | Sir karam makram"></HelmetTags>
      <div className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
        <h2 className="w-full text-end text-4xl font-bold md:text-center mb-12">
          واتساب
        </h2>
        <form
          onSubmit={handleSubmit}
          className="cardS1 form flex w-3/4 md:w-full !mx-auto flex-col items-start  justify-center gap-6"
        >
          <h2 className="mb-8 w-full text-center">
            من فضلك ادخل النص المراد ارسالة
          </h2>
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMesaage(e.target.value)}
            placeholder="ادخل الرسالة"
            className="input p-4 w-full h-[120px] disabled:opacity-50 disabled:cursor-not-allowed"
          />

          <div className="w-full">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("whatsapp_send")
              )) && (
              <button
                type="submit"
                disabled={sendMessageToWhatsappLoading}
                className=" login-button mt-10"
              >
                {sendMessageToWhatsappLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "ارسال الرسالة"
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default SendWhatsappMessage;
