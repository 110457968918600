import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";

function Attendance() {
  const table = useTable("attendance", true);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="الحضور | Sir karam makram"></HelmetTags>
      <h2 className="mb-20 w-full text-end text-4xl font-bold md:text-center">
        الحضور
      </h2>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Attendance;
